import { $, addAction } from '@situation/setdesign.util';
import lax from 'lax.js';

/**
 * Sets up animations on page load
 * @function
 * @returns {void}
 */
const setupAnimations = () => {
    lax.init();
    lax.addDriver('scrollY', () => window.scrollY);
    lax.addElements('.content-section:not(:first-child) .content-column', {
        scrollY: {
            opacity: [
                ['elInY+50', 'elInY+screenHeight/3'],
                [0, 1],
            ],
            translateY: [['elInY+50', 'elInY+screenHeight/3'], [50, 0], { easing: 'easeOutQuart' }],
        },
    });
};

/**
 * Adds home animations after a delay
 * @function
 * @returns {void}
 */
const addHomeAnimations = () => {
    lax.addElements('.home-hero-lockup__tagline, .home-hero-lockup__billing', {
        scrollY: { opacity: [[0, 'elHeight*2'], [1, 0], { easing: 'easeOutQuad' }] },
    });

    lax.addElements('.home-hero-lockup__logo', {
        scrollY: {
            translateY: [[0, 'elOutY'], [0, 'screenHeight*.2'], { easing: 'easeInQuad' }],
            scale: [[0, 'elOutY'], [1, 0.7], { easing: 'easeInQuad' }],
            opacity: [
                [0, 'elOutY'],
                [1, 0],
            ],
        },
    });

    lax.addElements('.home-hero-lockup__statue', {
        scrollY: {
            translateY: [[0, 'screenHeight'], [0, 'screenHeight*.5'], { easing: 'easeInQuad' }],
            scale: [[0, 'screenHeight'], [1, 1.3], { easing: 'easeOutQuart' }],
            opacity: [
                [0, 'elOutY'],
                [1, 0],
            ],
        },
    });
};

/**
 * Sets up animations after page load is complete
 * @function
 * @returns {void}
 */
const onPageLoad = () => {
    setupAnimations();
    addAction(
        'toggleActive',
        () => {
            lax.removeElements('.content-section:not(:first-child) .content-column');
            setupAnimations();
        },
        20,
    );

    if ($('.home-hero-lockup').length) {
        $('.home-hero-lockup--animate-in').removeClass('home-hero-lockup--animate-in');
        setTimeout(addHomeAnimations, 1000);
    }
};

$(window).on('load', onPageLoad);
